import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class SuccessPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact Form: Alex Covo Studio NYC" />
        <article className="post-content page-template no-image">
<div className="post-content-body">
<section>
  <div className="row1">




        <div className="col-lg-12 text-center">
                            <h2 className="contactTitle">Success!</h2>
                            <h3 className="subTitle">Hurray! We've received your message</h3>
                            <h3 className="subTitle">We'll get back to you shortly :)</h3>
                            <br></br>
                          </div>
  </div>
  </section>
  </div>
          </article>
      </Layout>
    )
  }
}

export default SuccessPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
